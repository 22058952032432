import React from 'react';
import config from '../../config';
import revtelLogo from '../assets/images/revtelLogo.png';

export default function Footer() {
  return (
    <footer id="footer">
      <p className="copyright">
        <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        <img src={revtelLogo} style={{width: 20, height:20, marginRight:10}} />
        <a href="https://www.revtel.tech/" target='_blank' >RevtelTech 忻旅科技股份有限公司</a>
        </div>
      </p>
    </footer>
  );
}
