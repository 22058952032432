module.exports = {
  siteTitle: 'RevCertCard 資安識別卡', // <title>
  siteDescription: 'RevCertCard - 用高規格 NFC 卡為您打造最高品質最安全的品牌印象',

  siteTitleEN: 'RevCertCard',
  siteDescriptionEN: 'RevCertCard - The safest NFC card / tag solution',

  manifestName: 'Fractal',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-fractal/`, // This path is subpath of your hosting https://domain/portfolio
  
  heading: 'RevCertCard 資安識別卡',
  subHeading: '虛實整合及極致資安間的最佳平衡',

  headingEN: 'RevCertCard',
  subHeadingEN: 'The Best Balance between Security and Branding',

  googleAnalyticsId: 'UA-189696388-1',
  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/anubhavsrivastava',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/onlyanubhav',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/theanubhav',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:anubhav.srivastava00@gmail.com',
    },
  ],
};
